import React, { useState, useEffect, useRef } from "react";
import "./App.css";
import Fonts from "./Fonts";

function App() {
  const words = ["Disneyland", "Raw power", "Mint Green", "DaDaDaDaDaDaDa"];
  const [text, setText] = useState(
    words[parseInt(Math.random() * words.length)]
  );
  const [lines, setLines] = useState(50);
  const [delay, setDelay] = useState(1);
  const [size, setSize] = useState(16);
  const [lineHei, setLineHei] = useState(1);
  const [hideGUI, sethideGUI] = useState(false);
  const [textTransform, setTextTransform] = useState("capitalize");
  // const [speed, setSpeed] = useState(1000);

  const [color, setColor] = useState("#000000");
  const [bgColor, setBgColor] = useState("#ffffff");
  const [fontFamily, setFontFamily] = useState("Helvetica");

  // Spacings state
  const [spacings, setSpacings] = useState(Array(lines).fill([0, 0, 0]));

  // Ref for the animation ID
  const frameIdRef = useRef();

  useEffect(() => {
    // Start time for the animation
    const startTime = Date.now();

    const animate = () => {
      const currentTime = Date.now();
      const baseElapsedTime = (currentTime - startTime) / 1000;

      // Generate new spacings based on the elapsed time
      const newSpacings = Array.from({ length: lines }, (_, lineIndex) => [
        Math.max(0, Math.sin(baseElapsedTime - lineIndex * 0.06) * 100), // Left
        Math.max(
          0,
          Math.sin(baseElapsedTime - lineIndex * 0.06 - delay * 0.3) * 110
        ), // Center
        Math.max(
          0,
          Math.sin(baseElapsedTime - lineIndex * 0.06 - delay * 0.6) * 130
        ), // Right
      ]);

      setSpacings(newSpacings);

      //  next animation frame
      frameIdRef.current = requestAnimationFrame(animate);
    };

    // Start animation
    frameIdRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(frameIdRef.current);
  }, [lines, delay]);

  return (
    <div className="App" style={{ background: bgColor }}>
      <>
        <button onClick={() => sethideGUI(!hideGUI)}>
          {hideGUI ? "+" : "x"}
        </button>
        <div className="gui" style={{ display: hideGUI ? "none" : "block" }}>
          <div>
            <label>Text</label>

            <input
              style={{ textTransform }}
              type="text"
              onChange={(e) => setText(e.target.value)}
              value={text}
              // TODO set title page to text
              title={text}
            />
          </div>

          <div>
            <label>
              <span>Font </span>
            </label>
            <select
              value={fontFamily}
              onChange={(e) => setFontFamily(e.target.value)}
            >
              <Fonts></Fonts>
            </select>
          </div>

          <div>
            <div>
              <label>TT</label>
              <input
                type="radio"
                onChange={(e) => setTextTransform(e.target.value)}
                value="uppercase"
                checked={textTransform === "uppercase"}
              />
            </div>

            <div>
              <label>Tt</label>
              <input
                type="radio"
                onChange={(e) => setTextTransform(e.target.value)}
                value="capitalize"
                checked={textTransform === "capitalize"}
              />
            </div>

            <div>
              <label>tt</label>
              <input
                type="radio"
                onChange={(e) => setTextTransform(e.target.value)}
                value="lowercase"
                checked={textTransform === "lowercase"}
              />
            </div>
          </div>

          {/* <div>
            <label>
              <span>Speed </span>
              <span> {speed}</span>
            </label>
            <input
              type="range"
              min={1}
              max={2000}
              onChange={(e) => setSpeed(parseInt(e.target.value))}
              value={speed}
            />
          </div> */}

          <div>
            <label>
              <span>Ammount </span>
              <span> {lines}</span>
            </label>
            <input
              type="range"
              min={1}
              max={300}
              onChange={(e) => setLines(parseInt(e.target.value))}
              value={lines}
            />
          </div>

          <div>
            <label>
              <span>Size </span>
              <span> {size}</span>
            </label>
            <input
              type="range"
              min={6}
              max={128}
              step={0.01}
              onChange={(e) => setSize(parseFloat(e.target.value))}
              value={size}
            />
          </div>

          <div>
            <label>
              <span>Line Height </span>
              <span> {lineHei}</span>
            </label>
            <input
              type="range"
              min={1}
              max={10}
              step={0.01}
              onChange={(e) => setLineHei(parseFloat(e.target.value))}
              value={lineHei}
            />
          </div>

          <div>
            <label>
              <span>Delay </span>
              <span> {delay}</span>
            </label>
            <input
              type="range"
              min={0}
              max={40}
              step={0.01}
              onChange={(e) => setDelay(parseFloat(e.target.value))}
              value={delay}
            />
          </div>

          <div>
            <label>
              <span>Color </span>
              <span style={{ color }}> {color}</span>
            </label>
            <input
              type="color"
              value={color}
              onChange={(e) => setColor(e.target.value)}
            />
          </div>

          <div>
            <label>
              <span> BG Color </span>
              <span style={{ color: bgColor }}> {bgColor}</span>
            </label>
            <input
              type="color"
              value={bgColor}
              onChange={(e) => setBgColor(e.target.value)}
            />
          </div>

          <button
            onClick={() => {
              sethideGUI(true);

              window.print();
              sethideGUI(false);
            }}
            style={{
              width: "100%",
              background: "transparent",
              border: "none",
              textAlign: "left",
              padding: "0",
              margin: "0",
              fontWeight: "bold",
            }}
          >
            Screen
          </button>
        </div>
      </>

      {spacings.map((lineSpacing, lineIndex) => (
        <div
          key={lineIndex}
          className="line"
          style={{
            fontSize: `${size}px`,
            display: "flex",
            color,
            lineHeight: lineHei + "em",
            fontFamily,
            textTransform,
          }}
        >
          {lineSpacing.map((wordSpacing, wordIndex) => (
            <span
              key={`${lineIndex}-${wordIndex}`}
              style={{
                letterSpacing: `${wordSpacing}px`,
                textAlign:
                  wordIndex === 0
                    ? "left"
                    : wordIndex === 1
                    ? "center"
                    : "right",
              }}
            >
              {text}
            </span>
          ))}
        </div>
      ))}
    </div>
  );
}

export default App;
