import React from "react";

export default function Fonts() {
  return (
    <>
      <option value="Arial">Arial</option>
      <option value="Arial Black">Arial Black</option>
      <option value="Verdana">Verdana</option>
      <option value="Tahoma">Tahoma</option>
      <option value="Trebuchet MS">Trebuchet MS</option>
      <option value="Impact">Impact</option>
      <option value="Times New Roman">Times New Roman</option>
      <option value="Didot">Didot</option>
      <option value="Georgia">Georgia</option>
      <option value="American Typewriter">American Typewriter</option>
      <option value="Andalé Mono">Andalé Mono</option>
      <option value="Courier">Courier</option>
      <option value="Lucida Console">Lucida Console</option>
      <option value="Monaco">Monaco</option>
      <option value="Bradley Hand">Bradley Hand</option>
      <option value="Brush Script MT">Brush Script MT</option>
      <option value="Luminari">Luminari</option>
      <option value="Comic Sans MS">Comic Sans MS</option>
      <option value="Helvetica">Helvetica</option>
      <option value="Helvetica Neue">Helvetica Neue</option>
      <option value="Futura">Futura</option>
      <option value="Gill Sans">Gill Sans</option>
      <option value="Baskerville">Baskerville</option>
      <option value="Calisto MT">Calisto MT</option>
      <option value="Cambria">Cambria</option>
      <option value="Garamond">Garamond</option>
      <option value="Palatino">Palatino</option>
      <option value="Perpetua">Perpetua</option>
      <option value="Rockwell">Rockwell</option>
      <option value="Rockwell Extra Bold">Rockwell Extra Bold</option>
      <option value="Bodoni MT">Bodoni MT</option>
      <option value="Copperplate">Copperplate</option>
      <option value="Papyrus">Papyrus</option>
      <option value="Marker Felt">Marker Felt</option>
      <option value="Trattatello">Trattatello</option>
    </>
  );
}
